import { UICtx } from '@frontend/sorghum/external-providers';
import { Box, Typography } from '@mui/material';
import { styled, Theme, useTheme } from '@mui/material/styles';
import { FC, ReactElement, useContext } from 'react';

const NoticeBarStyled = styled(Box)<{
  $isOpenLeftSidebar: boolean;
  $isOpenProjectList: boolean;
}>(({ theme, $isOpenLeftSidebar, $isOpenProjectList }) => ({
  height: '36px',
  width: `${
    $isOpenLeftSidebar && $isOpenProjectList
      ? 'calc(100% - 560px)'
      : !$isOpenLeftSidebar && $isOpenProjectList
      ? 'calc(100% - 340px)'
      : $isOpenLeftSidebar
      ? 'calc(100% - 280px)'
      : 'calc(100% - 60px)'
  }`,
  position: 'fixed',
  zIndex: 999,
  transition: '0.1s',
}));

const FixedBoxStyled = styled(Box)<{ type: 'system' | 'error' | 'info' }>(
  ({ theme, type }) => {
    const styles = getStylesForType(type, theme);
    return {
      background: styles.backgroundColor,
      height: '36px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 99,
    };
  },
);

const MessageStyled = styled(Typography)(({ theme }) => ({
  marginRight: '24px',
}));

const LinkStyled = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
}));

interface NoticeBarProps {
  message: string | ReactElement;
  linkText?: string;
  onLinkClick?: () => void;
  type?: 'system' | 'error' | 'info';
}

const getStylesForType = (type: 'system' | 'error' | 'info', theme: Theme) => {
  switch (type) {
    case 'system':
      return {
        backgroundColor: theme.palette.warning.light,
        messageColor: theme.palette.grey[700],
        linkColor: theme.palette.info.main,
      };
    case 'error':
      return {
        backgroundColor: theme.palette.error.light,
        messageColor: theme.palette.error.dark,
        linkColor: theme.palette.info.main,
      };
    case 'info':
      return {
        backgroundColor: theme.palette.grey[800],
        messageColor: theme.palette.grey.white,
        linkColor: theme.palette.grey.white,
      };
    default:
      return {
        backgroundColor: theme.palette.warning.light,
        messageColor: theme.palette.grey[700],
        linkColor: theme.palette.info.main,
      };
  }
};

export const NoticeBar: FC<NoticeBarProps> = ({
  message,
  linkText,
  onLinkClick,
  // default
  type = 'system',
}: NoticeBarProps) => {
  const theme = useTheme();
  const styles = getStylesForType(type, theme);
  const uiState = useContext(UICtx);

  return (
    <NoticeBarStyled
      $isOpenLeftSidebar={uiState.isOpenLeftSidebar}
      $isOpenProjectList={uiState.isOpenProjectList}
    >
      <FixedBoxStyled type={type}>
        <MessageStyled variant="body2" color={styles.messageColor}>
          {message}
        </MessageStyled>
        {linkText && (
          <LinkStyled
            variant="overlineBold"
            color={styles.linkColor}
            onClick={onLinkClick}
          >
            {linkText}
          </LinkStyled>
        )}
      </FixedBoxStyled>
    </NoticeBarStyled>
  );
};

export default NoticeBar;
