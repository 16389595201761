import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';
import { findWithRegex } from '@frontend/components/utils';
import { MaterialStyledProps } from '@frontend/editor/interface';
import {
  Box,
  Divider,
  Menu,
  Typography,
  TypographyVariantsOptions,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  CompositeDecorator,
  ContentBlock,
  EditorState,
  Modifier,
} from 'draft-js';
import React, {
  CSSProperties,
  ReactElement,
  ReactNode,
  ReactPortal,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ReactComponent as TextLabelIcon } from '../../images/text-label-icon.svg';
import { MenuItem } from '../menu/item/item';
interface DivWrapperStyledProps extends MaterialStyledProps {
  $allowEnter: boolean;
  $hasError: boolean;
  $hasFocus: boolean;
  $disabled: boolean;
  $readonly: boolean;
  $placeholder: boolean;
  $hasBorder?: boolean;
  $height?: number;
  $maxHeight?: number;
  $overflow?: string;
}

interface TextareaProps {
  defaultValue?: string;
  readonly?: boolean;
  disabled?: boolean;
  labelItem: { name: string; type: number }[];
  styles?: CSSProperties;
  limit?: number;
  placeholder?: string;
  // 只有 onblur 時才會觸發錯誤狀態
  error?: boolean;
  focus?: boolean;
  // 長駐的錯誤狀態
  publishError?: boolean;
  // 允許換行
  allowEnter?: boolean;
  height?: number;
  border?: boolean;
  maxHeight?: number;
  overflow?: string;
  onChange?: (text: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onEmojiOpen?: () => void;
  onEmojiClose?: () => void;
  hasLabelItem?: boolean;
}

const DivWrapperStyled = styled(Box)<DivWrapperStyledProps>(
  ({
    theme,
    $allowEnter,
    $hasFocus,
    $disabled,
    $readonly,
    $hasError,
    $placeholder,
    $height,
    $hasBorder,
    $maxHeight,
    $overflow,
  }) => ({
    '& > .DraftEditor-root': {
      display: 'flex',
      width: '100%',
      height: $height
        ? `${$height}px`
        : !$hasFocus && $placeholder
        ? '36px'
        : 'auto',
      ...(theme.typography as TypographyVariantsOptions).body2,
      ...($height && { overflow: 'auto' }),
      border: `1px solid ${
        $hasBorder
          ? $hasFocus && !$disabled
            ? theme.palette['primary']['main']
            : theme.palette['grey'][300]
          : $hasFocus
          ? theme.palette['info']['main']
          : $hasError
          ? theme.palette['error']['main']
          : 'inherit'
      }`,
      // placeholder css
      '& .public-DraftEditorPlaceholder-inner': {
        whiteSpace: 'nowrap !important',
        color: theme.palette['grey'][400],
      },
      color:
        $disabled && !$readonly
          ? theme.palette['grey'][500]
          : theme.palette['grey']['900'],
      borderRadius: '4px',
      padding: '6px 12px',
      ...($hasFocus && {
        background: theme.palette['grey']['white'],
      }),
      cursor: 'text',
      lineHeight: 2,
      ...($maxHeight && { maxHeight: `${$maxHeight}px` }),
      ...($overflow && { overflow: $overflow }),
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },

    '& .DraftEditor-editorContainer': {
      width: '100%',

      ...(!$allowEnter && {
        display: 'inline-flex',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }),
    },

    '& .custom-mention': {
      color: '#0062ff',
      backgroundColor: 'rgba(0, 98, 255, 0.05)',
      padding: 4,
      borderRadius: 4,
    },
    // '& .custom-mention-suggestions': {
    //   backgroundColor: 'rgba(255, 255, 255, 1)',
    //   zIndex: 1,
    //   padding: theme.spacing(1),
    // },
  }),
);

const EditorTooltipStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(0),
  bottom: theme.spacing(-4),
  background: theme.palette['bluegrey'][700],
  borderRadius: '4px',
  padding: '6px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  userSelect: 'none',
  cursor: 'pointer',
  zIndex: 99,
}));

const IconStyled = styled(Box)<{ $disabled: boolean }>(
  ({ theme, $disabled }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '16px',
    marginRight: '5px',
    color: $disabled
      ? theme.palette['bluegrey'][500]
      : theme.palette['grey']['white'],

    '& > svg > g > path': {
      ...($disabled && { fill: theme.palette['bluegrey'][500] }),
    },

    '& button': {
      background: 'inherit',
      border: 0,
      padding: 0,
      margin: 0,
      width: 'auto',

      '&:hover': {
        background: 'inherit',
      },
    },
  }),
);

const TextCountWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '12px',
  width: 'fit-content',
}));

const MenuStyled = styled(Menu)(({ theme }) => ({
  '.MuiMenu-list': {
    width: '294px',
    maxHeight: '432px',
    overflowY: 'scroll',
  },

  '.MuiMenuItem-root': {
    whiteSpace: 'inherit',
    wordBreak: 'break-word',
  },
}));

const LABEL_STRING_LENGTH = 19;
const EMOJI_STRING_LENGTH = 3;

export const getTextareaContentLength = (
  content: string,
  keys: { name: string; type: number }[],
) => {
  // 逐一檢查 keys 中的字串
  keys.forEach((key) => {
    // 檢查 content 中是否包含 key
    if (content.includes(key.name)) {
      // 將 key 替換為指定長度的空白字串
      const replacement = ' '.repeat(LABEL_STRING_LENGTH);
      content = content.replace(key.name, replacement);
    }
  });
  // emoji replace 時會多一個字元
  const replacement = ' '.repeat(EMOJI_STRING_LENGTH - 1);
  content = content.replace(
    // 找 emoji 的正則
    /[\uD83C-\uDBFF\uDC00-\uDFFF\u2600-\u26FF\u2700-\u27BF]/u,
    replacement,
  );

  // 回傳修改後的 content 的字數
  return content.length;
};

//TODO: 下拉選單寬度限制
export const Textarea: React.FC<TextareaProps> = ({
  defaultValue,
  disabled = false,
  labelItem,
  styles,
  error = false,
  limit = 99999,
  placeholder = '',
  readonly = false,
  focus,
  height,
  border,
  allowEnter = true,
  maxHeight,
  overflow,
  onChange = () => ({}),
  onFocus,
  onBlur,
  onEmojiOpen,
  onEmojiClose,
  // 發布後才要判斷的錯誤
  publishError = false,
  hasLabelItem = true,
}) => {
  const ref = useRef<Editor>(null);
  const [editorText, setEditorText] = useState(defaultValue ?? '');
  const [editorState, setEditorState] = useState(() => {
    return createEditorStateWithText(defaultValue ?? '');
  });
  const [isCompositeDecoratorChanged, setIsCompositeDecoratorChanged] =
    useState<boolean>(false);
  const [isFocus, setIsFocus] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const ellipsisRef = useRef<HTMLElement>(null);

  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [isNewMention, setIsNewMention] = useState(false);
  const [currentMention, setCurrentMention] = useState({
    start: 0,
    end: 0,
  });
  // onBlur 時才顯示錯誤的紅框
  const [isError, setIsError] = useState<boolean>(false);
  const openModal = Boolean(anchorEl);

  // 取得內文內容的 JSON 格式
  const content = editorState.getCurrentContent();
  // 將 JSON 格式的內容轉換成純文字內容
  const contentText = content.getPlainText();
  const length = getTextareaContentLength(contentText, labelItem);

  // 打開 ellipsis 選單
  const handleEllipsisClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (length > limit - LABEL_STRING_LENGTH) {
        return;
      }
      e.preventDefault();
      // 打開選單
      setAnchorEl(ellipsisRef.current);
      // 從工具列打開一定是新增
      setIsNewMention(true);

      // 插入空格
      // const contentStateWithEntity = contentState.createEntity(
      //   'MENTION',
      //   'IMMUTABLE',
      //   { value: '', name: '' },
      // );
      // const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      // const selectionState = editorState.getSelection();
      // const contentStateWithText = Modifier.insertText(
      //   contentStateWithEntity,
      //   selectionState,
      //   '',
      //   undefined,
      //   entityKey,
      // );

      // const newEditorState = EditorState.push(
      //   editorState,
      //   contentStateWithText,
      //   'insert-characters',
      // );

      // const selection = newEditorState.getSelection();
      // const mentionOffset = selection.getFocusOffset();
      // const newSelection = selection.merge({
      //   anchorOffset: mentionOffset,
      //   focusOffset: mentionOffset,
      // });
      // const newEditorStateWithSelection = EditorState.forceSelection(
      //   newEditorState,
      //   newSelection,
      // );

      // setEditorState(newEditorStateWithSelection);
    },
    [limit, length],
  );

  // 關閉 ellipsis 選單
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  // custom mention
  const CustomSpanComponent = (props: {
    start?: number;
    end?: number;
    children: ContentBlock | null | ReactNode | ReactPortal;
  }) => {
    const spanRef = useRef<HTMLElement>(null);

    const openMenuAndRecordMentionData = useCallback(() => {
      setAnchorEl(spanRef.current);
      // 點擊可以替換標籤
      setIsNewMention(false);
      // 儲存當下點擊的標籤資料
      if (props.start && props.end) {
        setCurrentMention({
          // 預設值由 compositeDecorator 轉換的標籤會有起始跟結束位置
          start: props.start,
          end: props.end,
        });
      } else {
        // TODO : trigger 增加的標籤結構不一樣，也許有更好的做法
        const portal = props.children as ReactElement[];
        if (portal) {
          const target = portal[0].props;
          setCurrentMention({
            start: target.start,
            end: target.start + target.text.length,
          });
        }
      }
    }, [props]);

    return (
      <span
        ref={spanRef}
        onClick={openMenuAndRecordMentionData}
        className="custom-mention"
      >
        {props.children as ReactNode}
      </span>
    );
  };

  const compositeDecorator = useMemo(() => {
    if (labelItem && !anchorEl) {
      return new CompositeDecorator(
        // 將傳入的 label item 轉為 composite 正則表達式，可以提供比對字串並套上
        labelItem.map((item) => ({
          strategy: function (contentBlock, callback) {
            findWithRegex(new RegExp(item.name, 'g'), contentBlock, callback);
          },
          component: CustomSpanComponent,
        })),
      );
    } else {
      return null;
    }
  }, [labelItem, anchorEl]);

  const handleOnChange = useCallback(
    (newState: EditorState) => {
      // 要帶入 decorator
      if (compositeDecorator) {
        const newEditorState = EditorState.set(newState, {
          decorator: compositeDecorator,
        });
        setEditorState(newEditorState);
      } else {
        setEditorState(newState);
      }

      // 取得內文內容的 JSON 格式
      const content = newState.getCurrentContent();
      // 將 JSON 格式的內容轉換成純文字內容
      const contentText = content.getPlainText();
      // 值不一樣才需要更新
      if (contentText !== defaultValue) {
        onChange(contentText);
        setEditorText(contentText);
      }
    },
    [onChange, defaultValue, compositeDecorator],
  );

  const handleInsertEmoji = useCallback(
    (emojiUnicode: string) => {
      const contentState = editorState.getCurrentContent();
      const selectionState = editorState.getSelection();

      const contentStateWithEntity = contentState.createEntity(
        'emoji',
        'IMMUTABLE',
        { emoji: emojiUnicode },
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

      const newContentState = Modifier.insertText(
        contentStateWithEntity,
        selectionState,
        emojiUnicode,
        undefined,
        entityKey,
      );

      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        'insert-characters',
      );

      setEditorState(newEditorState);
      handleOnChange(newEditorState);
    },
    [editorState, handleOnChange],
  );

  // 點擊 ellipsis menu item
  const insertMention = useCallback(
    (mention: string) => {
      const contentState = editorState.getCurrentContent();
      const selection = editorState.getSelection();

      // 建立標籤的實體
      const contentStateWithEntity = contentState.createEntity(
        'MENTION',
        'IMMUTABLE',
        { value: mention, name: mention },
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

      if (isNewMention) {
        const blockKey = selection.getStartKey();
        const startOffset = selection.getStartOffset();
        const block = contentState.getBlockForKey(blockKey);
        const blockText = block.getText();

        const contentStateWithText = Modifier.replaceText(
          contentState,
          // 如果是由 { 觸發選單的則要取代多餘的前綴
          blockText.charAt(startOffset - 1) !== '{'
            ? selection
            : selection.merge({
                anchorOffset: startOffset - 1,
                focusOffset: startOffset,
              }),
          mention,
          undefined,
          entityKey,
        );
        const newEditorState = EditorState.push(
          editorState,
          contentStateWithText,
          'insert-characters',
        );

        setEditorState(newEditorState);
      } else {
        // 把整個原本的 mention 換成新的
        const contentStateWithText = Modifier.replaceText(
          contentStateWithEntity,
          selection.merge({
            anchorOffset: currentMention.start,
            focusOffset: currentMention.end,
          }),
          mention,
          undefined,
          entityKey,
        );

        const newEditorState = EditorState.push(
          editorState,
          contentStateWithText,
          'insert-characters',
        );

        const anchorOffset = currentMention.start + mention.length + 1;
        const newSelection = selection.merge({
          anchorOffset,
          focusOffset: anchorOffset,
        });

        const newEditorStateWithSelection = EditorState.forceSelection(
          newEditorState,
          newSelection,
        );

        setEditorState(newEditorStateWithSelection);
      }
    },
    [editorState, isNewMention, currentMention.start, currentMention.end],
  );

  const handleBeforeInput = useCallback(
    (chars: string, _editorState: EditorState) => {
      // 如果有選單被打開則阻擋輸入
      if (isMenuOpen || !!anchorEl) {
        return 'handled';
      }
      const contentState = _editorState.getCurrentContent();
      const contentText = contentState.getPlainText();
      const currentCharCount = getTextareaContentLength(contentText, labelItem);

      const selectionState = _editorState.getSelection();

      const blockKey = selectionState.getStartKey();
      const startOffset = selectionState.getStartOffset();
      const block = contentState.getBlockForKey(blockKey);
      const blockText = block.getText();

      // 取得 window 中選取的位置作為選單打開的 anchor position
      const windowSelection = window.getSelection();
      if (!windowSelection) {
        return 'not-handled';
      }
      const range = windowSelection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const pageX = rect.x;
      const pageY = rect.y;

      // 是否觸發 mention 選單
      if (
        // 是否連續輸入兩個 {
        chars === '{' &&
        blockText.charAt(startOffset - 1) === '{'
      ) {
        setCurrentMention({
          // 紀錄觸發的起始跟結束位置
          start: selectionState.getStartOffset(),
          end: selectionState.getEndOffset(),
        });
        setIsNewMention(true);

        // 如果字數允許輸入標籤則打開選單
        if (
          currentCharCount + chars.length + LABEL_STRING_LENGTH - 2 <=
          limit
        ) {
          setAnchorPosition({ top: pageY, left: pageX });
          setIsMenuOpen(true);
          return 'handled';
        }
      }

      if (currentCharCount + chars.length > limit) {
        return 'handled';
      } else {
        return 'not-handled';
      }
    },
    [anchorEl, isMenuOpen, limit, labelItem],
  );

  const handlePastedText = useCallback(
    (text: string, _: string, editorState: EditorState) => {
      const contentState = editorState.getCurrentContent();

      const contentText = contentState.getPlainText();
      const currentCharCount = getTextareaContentLength(contentText, labelItem);
      const currentContent = editorState.getCurrentContent();

      // 計算剩餘空間
      const remainingSpace = limit - currentCharCount;

      try {
        // 如果不允許換行，移除換行符號
        if (!allowEnter) {
          if (text.includes('\n')) {
            text = text.replace(/\n/g, '');
          }
        }
        // 如果剩餘空間 >= 複製的文本長度，直接貼上
        if (remainingSpace >= text.length) {
          const newContent = Modifier.insertText(
            currentContent,
            editorState.getSelection(),
            text,
          );
          const newEditorState = EditorState.push(
            editorState,
            newContent,
            'insert-characters',
          );
          setEditorState(newEditorState);

          return 'handled';
        } else if (remainingSpace > 0) {
          // 如果剩餘空間小於複製長度，截取剩餘長度後貼上
          const newText = text.substring(0, remainingSpace);
          const newContent = Modifier.insertText(
            currentContent,
            editorState.getSelection(),
            newText,
          );
          const newEditorState = EditorState.push(
            editorState,
            newContent,
            'insert-characters',
          );
          setEditorState(newEditorState);

          return 'handled';
        } else {
          // 如果剩餘長度不足什麼都不做
          return 'handled';
        }
      } catch {
        return 'not-handled';
      }
    },
    [labelItem, limit, allowEnter],
  );
  const hasFocus =
    readonly || disabled ? false : editorState.getSelection().getHasFocus();

  const handleKeyCommand = useCallback(
    (command: string, editorState: EditorState, eventTimeStamp: number) => {
      // 如果有選單被打開則阻擋 key command
      if (isMenuOpen || !!anchorEl) {
        return 'handled';
      }
      // 阻止換行
      if (command === 'split-block' && !allowEnter) {
        return 'handled';
      } else {
        return 'not-handled';
      }
    },
    [allowEnter, anchorEl, isMenuOpen],
  );

  useEffect(() => {
    setIsCompositeDecoratorChanged(true);
  }, [compositeDecorator]);

  useEffect(() => {
    if (compositeDecorator && isCompositeDecoratorChanged) {
      setIsCompositeDecoratorChanged(false);
      // 如果傳入的參數更新，要更新 decorator
      const newEditorState = EditorState.set(editorState, {
        decorator: compositeDecorator,
      });
      setEditorState(newEditorState);
    }
  }, [isCompositeDecoratorChanged, editorState, compositeDecorator]);

  useEffect(() => {
    // 如果有按下發布，publishError 會改變以 publishError 的狀態為主，onblur 時則以當下的狀態為主
    setIsError(publishError);
  }, [publishError]);

  useEffect(() => {
    if (focus) {
      setIsFocus(true);
      setTimeout(() => ref.current?.focus(), 0);
    }
  }, [focus]);

  // 更新預設值
  useEffect(() => {
    // 如果有傳入新的值才更新
    if (editorText !== defaultValue) {
      setEditorState(createEditorStateWithText(defaultValue || ''));
      setEditorText(defaultValue || '');
    }
  }, [defaultValue, editorText]);

  return (
    <DivWrapperStyled
      {...(styles && { style: styles })}
      $height={height}
      $hasBorder={border}
      position="relative"
      $hasFocus={hasFocus}
      $disabled={disabled}
      $readonly={readonly}
      $hasError={isError}
      $allowEnter={allowEnter}
      $placeholder={!!placeholder && length <= 0}
      $maxHeight={maxHeight}
      $overflow={overflow}
      onClick={() => ref.current?.focus()}
      onFocus={() => {
        if (readonly) {
          return;
        }
        setIsFocus(true);
        if (onFocus) {
          onFocus();
        }
      }}
      onBlur={() => {
        if (readonly) {
          return;
        }
        setIsFocus(false);
        setIsError(error);
        if (onBlur) {
          onBlur();
        }
      }}
    >
      <Editor
        ref={ref}
        handleKeyCommand={handleKeyCommand}
        readOnly={disabled || readonly}
        editorKey={'editor'}
        editorState={editorState}
        onChange={handleOnChange}
        handlePastedText={handlePastedText}
        handleBeforeInput={handleBeforeInput}
        placeholder={hasFocus ? undefined : placeholder}
      />
      {isFocus && !disabled && (
        <EditorTooltipStyled onMouseDown={(e) => e.preventDefault()}>
          {hasLabelItem && (
            <IconStyled
              $disabled={length > limit - LABEL_STRING_LENGTH}
              ref={ellipsisRef}
              onMouseDown={handleEllipsisClick}
            >
              <TextLabelIcon />
            </IconStyled>
          )}
          {/* <IconStyled $disabled={length > limit - EMOJI_STRING_LENGTH}>
            <Emoji
              renderButton={<SentimentSatisfiedAltIcon fontSize="tiny" />}
              insertEmoji={handleInsertEmoji}
              isDisabled={length > limit - EMOJI_STRING_LENGTH}
              onEmojiOpen={onEmojiOpen}
              onEmojiClose={onEmojiClose}
            />
          </IconStyled> */}
          <TextCountWrapper>
            <Typography
              variant="notoSans"
              color={length >= limit ? 'error' : '#33cc4b'}
            >
              {limit - length}
            </Typography>
          </TextCountWrapper>

          {/* ...打開的 parameter 選單 */}
          <MenuStyled
            disableAutoFocus={true}
            disableEnforceFocus={true}
            anchorEl={anchorEl}
            open={openModal}
            onClose={handleClose}
          >
            {labelItem &&
              labelItem
                .filter((i) => i.type === 1)
                .map((i, index) => {
                  return (
                    <MenuItem
                      key={`${i.name}_${index}`}
                      content={i.name}
                      onClick={() => {
                        setAnchorEl(null);
                        insertMention(i.name);
                      }}
                    />
                  );
                })}
            {labelItem &&
              labelItem
                .filter((i) => i.type === 3)
                .map((i, index) => {
                  return (
                    <MenuItem
                      key={`${i.name}_${index}`}
                      content={i.name}
                      onClick={() => {
                        setAnchorEl(null);
                        insertMention(i.name);
                      }}
                    />
                  );
                })}
            {labelItem && labelItem.filter((i) => i.type === 2).length > 0 && (
              <Divider />
            )}
            {labelItem &&
              labelItem
                .filter((i) => i.type === 2)
                .map((i, index) => {
                  return (
                    <MenuItem
                      key={`${i.name}_${index}`}
                      content={i.name}
                      onClick={() => {
                        setAnchorEl(null);
                        insertMention(i.name);
                      }}
                    />
                  );
                })}
          </MenuStyled>

          {/* 文字輸入中觸發 {{ 打開的 parameter 選單 */}
          <MenuStyled
            disableAutoFocus={true}
            disableEnforceFocus={true}
            anchorReference="anchorPosition"
            anchorPosition={anchorPosition}
            open={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
          >
            {labelItem &&
              labelItem
                .filter((i) => i.type === 1)
                .map((i, index) => {
                  return (
                    <MenuItem
                      key={`${i.name}_${index}`}
                      content={i.name}
                      onClick={() => {
                        setIsMenuOpen(false);
                        insertMention(i.name);
                      }}
                    />
                  );
                }, [])}
            {labelItem &&
              labelItem
                .filter((i) => i.type === 3)
                .map((i, index) => {
                  return (
                    <MenuItem
                      key={`${i.name}_${index}`}
                      content={i.name}
                      onClick={() => {
                        setIsMenuOpen(false);
                        insertMention(i.name);
                      }}
                    />
                  );
                })}
            {labelItem && labelItem.filter((i) => i.type === 2).length > 0 && (
              <Divider />
            )}
            {labelItem &&
              labelItem
                .filter((i) => i.type === 2)
                .map((i, index) => {
                  return (
                    <MenuItem
                      key={`${i.name}_${index}`}
                      content={i.name}
                      onClick={() => {
                        setIsMenuOpen(false);
                        insertMention(i.name);
                      }}
                    />
                  );
                }, [])}
          </MenuStyled>
        </EditorTooltipStyled>
      )}
    </DivWrapperStyled>
  );
};

export default Textarea;
