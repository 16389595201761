import { Checkbox, Dialog, Typography } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import { formatNumberWithCommas } from '@frontend/editor/utils';
import {
  useDeleteBilling,
  useGetProject,
  useGetProjectOutcome,
  usePostProjectTrialSurvey,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  ModalTypesEnum,
  TrialSurveyItem,
  TrialSurveyProps,
} from '@frontend/sorghum/interface';
import { Textarea } from '@frontend/sorghum/ui';
import { PRICING_PAGE, usePath } from '@frontend/sorghum/utils';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SmsIcon from '@mui/icons-material/Sms';
import { Box, Link, List, ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DoneImage from '../../images/unsubscribe-survey-done.png';

interface BlockInfoType {
  title: string | React.ReactNode;
  type: 'normal' | 'success' | 'error';
}

interface BlockType {
  title: string;
  info: BlockInfoType[];
}

interface BlockContentType {
  title: string;
  description: string;
  confirmBtnText: string;
  cancelBtnText?: string;
  isNavigate?: boolean;
  disabled?: boolean;
  blocks: BlockType[];
  handleConfirm: () => void;
  handleCancel?: () => void;
  cancelBtnID?: string;
  confirmBtnID?: string;
}

const TEXT_LIMIT = 100;

const ContentContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  overflow: 'auto',
  marginTop: '-11px',
}));

const CheckboxesContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
  alignSelf: 'stretch',
}));

const InfoBlockContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: '20px',
}));

const InfoBlockWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '20px',
  background: theme.palette?.['bluegrey'][50],
  borderRadius: '8px',
  height: '240px',
}));

const CheckboxTextareaContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
  alignSelf: 'stretch',
}));

const TextareaContainerStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingLeft: '32px',
}));

const ListContainerStyled = styled(List)(({ theme }) => ({
  margin: 0,
  padding: '0 24px',
  listStyleType: 'disc',
}));

const DataContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: `${theme.palette['primary']['main']}0D`,
  borderRadius: '8px',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '40px 0 48px 0',
}));

const DataContentContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  marginTop: '32px',
}));

const DataBlockStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 20px 24px 20px',
  borderRadius: '12px',
  backgroundColor: theme.palette['grey']['white'],
  alignItems: 'center',
  justifyContent: 'center',
  width: '164px',
}));

const IconContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '34px',
  backgroundColor: `${theme.palette['primary']['main']}0D`,
  width: '24px',
  height: '24px',
}));

const enum SurveyStatus {
  DATA = -1,
  INFO = 1,
  ONE = 2,
  TWO = 3,
  DONE = 4,
}

const DataBlock = ({
  icon,
  num,
  title,
}: {
  icon: React.ReactNode;
  num: number;
  title: string;
}) => {
  return (
    <DataBlockStyled>
      <IconContainerStyled>{icon}</IconContainerStyled>
      <Typography variant="h5" color="grey.800" sx={{ margin: '8px 0' }}>
        {formatNumberWithCommas(num)}
      </Typography>
      <Typography variant="note" color="grey.600">
        {title}
      </Typography>
    </DataBlockStyled>
  );
};

export function PaymentSurveyModal() {
  const [t] = useTranslation();
  const uiState = useContext(UICtx);

  const [surveyStatus, setSurveyStatus] = useState<SurveyStatus>();
  const [surveyResult, setSurveyResult] = useState<TrialSurveyProps>({
    step: 2,
    reason: [],
    challenge: [],
  });

  const [shouldConfirmBtnDisabled, setShouldConfirmBtnDisabled] =
    useState<boolean>(true);

  const [
    shouldSurveyOneFeatureTextareaOpen,
    setShouldSurveyOneFeatureTextareaOpen,
  ] = useState<boolean>(false);
  const [
    shouldSurveyOneOtherTextareaOpen,
    setShouldSurveyOneOtherTextareaOpen,
  ] = useState<boolean>(false);
  const [
    shouldSurveyTwoOtherTextareaOpen,
    setShouldSurveyTwoOtherTextareaOpen,
  ] = useState<boolean>(false);

  const [surveyOneFeatureText, setSurveyOneFeatureText] = useState<string>('');
  const [surveyOneOtherText, setSurveyOneOtherText] = useState<string>('');
  const [surveyTwoOtherText, setSurveyTwoOtherText] = useState<string>('');

  const surveyOneFeatureTextareaRef = useRef<HTMLTextAreaElement>(null);
  const surveyOneOtherTextareaRef = useRef<HTMLTextAreaElement>(null);
  const surveyTwoOtherTextareaRef = useRef<HTMLTextAreaElement>(null);

  const { mutate: addNewTrialSurvey } = usePostProjectTrialSurvey();
  const { mutate: unsubscribe } = useDeleteBilling();
  const { data: projectID } = useGetProjectID();
  const { data: project } = useGetProject(projectID);
  const { navigateToUpgrade } = usePath();
  const { data: outcome } = useGetProjectOutcome(projectID);

  const handleConfirmBtnDisabled = useCallback(() => {
    const isReasonFeatureDescMissing = (items: TrialSurveyItem[]): boolean => {
      return items.some(
        (item) =>
          item.idx === 5 && (!item.desc || item.desc.trim().length === 0),
      );
    };
    const isReasonOtherDescMissing = (items: TrialSurveyItem[]): boolean => {
      return items.some(
        (item) =>
          item.idx === 6 && (!item.desc || item.desc.trim().length === 0),
      );
    };
    const isChallengeOtherDescMissing = (items: TrialSurveyItem[]): boolean => {
      return items.some(
        (item) =>
          item.idx === 5 && (!item.desc || item.desc.trim().length === 0),
      );
    };

    if (surveyStatus === SurveyStatus.ONE) {
      if (surveyResult.reason.length === 0) {
        return setShouldConfirmBtnDisabled(true);
      } else {
        if (isReasonFeatureDescMissing(surveyResult.reason)) {
          return setShouldConfirmBtnDisabled(true);
        } else if (isReasonOtherDescMissing(surveyResult.reason)) {
          return setShouldConfirmBtnDisabled(true);
        }
      }
    } else if (surveyStatus === SurveyStatus.TWO) {
      if (surveyResult.challenge.length === 0) {
        return setShouldConfirmBtnDisabled(true);
      } else {
        if (isChallengeOtherDescMissing(surveyResult.challenge)) {
          return setShouldConfirmBtnDisabled(true);
        }
      }
    }
    return setShouldConfirmBtnDisabled(false);
  }, [surveyStatus, surveyResult]);

  const handleUpgrade = useCallback(() => {
    navigateToUpgrade(projectID);
    uiState.closeModal();
  }, [projectID, navigateToUpgrade, uiState.closeModal]);

  const handleSurveyConfirm = useCallback(() => {
    if (surveyStatus === SurveyStatus.ONE) {
      setSurveyStatus(SurveyStatus.TWO);
    } else if (surveyStatus === SurveyStatus.TWO) {
      if (uiState.modalType === ModalTypesEnum.UNSUBSCRIBE_PAYMENT) {
        unsubscribe(
          {
            reason: surveyResult.reason,
            challenge: surveyResult.challenge,
          },
          {
            onSuccess: (res) => {
              if (res.code === 20000) setSurveyStatus(SurveyStatus.DONE);
            },
          },
        );
      } else {
        addNewTrialSurvey(surveyResult, {
          onSuccess: (res) => {
            if (res.code === 20000) setSurveyStatus(SurveyStatus.DONE);
          },
        });
      }
    } else if (surveyStatus === SurveyStatus.DONE) {
      uiState.closeModal();
    }
  }, [surveyStatus, surveyResult, handleConfirmBtnDisabled]);

  const handleSurveyBack = useCallback(() => {
    if (surveyStatus === SurveyStatus.ONE) {
      // back to free trial end cancel
    } else if (surveyStatus === SurveyStatus.TWO) {
      setSurveyStatus(SurveyStatus.ONE);
    }
  }, [surveyStatus, surveyResult, handleConfirmBtnDisabled]);

  const shouldChecked = useCallback(
    (idx: number): boolean => {
      if (surveyStatus === SurveyStatus.ONE) {
        if (surveyResult.reason.some((item) => item.idx === idx)) {
          return true;
        }
      } else if (surveyStatus === SurveyStatus.TWO) {
        if (surveyResult.challenge.some((item) => item.idx === idx)) {
          return true;
        }
      }
      return false;
    },
    [surveyStatus, surveyResult],
  );

  const handleCheck = useCallback(
    (target: string, checked: boolean) => {
      if (checked) {
        if (surveyStatus === SurveyStatus.ONE) {
          setSurveyResult({
            ...surveyResult,
            reason: [...surveyResult.reason, { idx: Number(target) }],
          });
          if (target === '5') {
            setShouldSurveyOneFeatureTextareaOpen(true);
            setTimeout(() => surveyOneFeatureTextareaRef.current?.focus(), 0);
          } else if (target === '6') {
            setShouldSurveyOneOtherTextareaOpen(true);
            setTimeout(() => surveyOneOtherTextareaRef.current?.focus(), 0);
          }
        } else if (surveyStatus === SurveyStatus.TWO) {
          setSurveyResult({
            ...surveyResult,
            challenge: [...surveyResult.challenge, { idx: Number(target) }],
          });
          if (target === '5') {
            setShouldSurveyTwoOtherTextareaOpen(true);
            setTimeout(() => surveyTwoOtherTextareaRef.current?.focus(), 0);
          }
        }
      } else {
        if (surveyStatus === SurveyStatus.ONE) {
          setSurveyResult({
            ...surveyResult,
            reason: surveyResult.reason.filter(
              (item) => item.idx !== Number(target),
            ),
          });
          if (target === '5') {
            setShouldSurveyOneFeatureTextareaOpen(false);
            setSurveyOneFeatureText('');
          } else if (target === '6') {
            setShouldSurveyOneOtherTextareaOpen(false);
            setSurveyOneOtherText('');
          }
        } else if (surveyStatus === SurveyStatus.TWO) {
          setSurveyResult({
            ...surveyResult,
            challenge: surveyResult.challenge.filter(
              (item) => item.idx !== Number(target),
            ),
          });
          if (target === '5') {
            setShouldSurveyTwoOtherTextareaOpen(false);
            setSurveyTwoOtherText('');
          }
        }
      }
    },
    [surveyStatus, surveyResult, handleConfirmBtnDisabled],
  );

  const totalAmountSaved = useMemo((): number => {
    const num = outcome?.customersUniqCount;
    if (!num) return 0;
    const amountSaved = num * 0.25 * 0.02 * 2000;
    return amountSaved;
  }, [outcome]);

  const {
    title,
    description,
    blocks,
    disabled,
    handleCancel,
    handleConfirm,
    isNavigate,
    cancelBtnText,
    confirmBtnText,
    cancelBtnID,
    confirmBtnID,
  }: BlockContentType = useMemo(() => {
    let title = '';
    let description = '';
    let cancelBtnText = '';
    let cancelBtnID = '';
    let confirmBtnText = '';
    let confirmBtnID = '';
    let disabled = false;
    let handleCancel = () => {};
    let handleConfirm = () => {};
    let isNavigate = false;
    let blocks: BlockType[] = [];

    switch (surveyStatus) {
      case SurveyStatus.DATA: {
        if (uiState.modalType === ModalTypesEnum.OFFICIAL_VERSION_LAUNCHED) {
          title = t('modal.officialLaunched.title');
          description = t('modal.officialLaunched.description1');
          cancelBtnText = t('modal.officialLaunched.startTrial');
          confirmBtnText = t('modal.officialLaunched.upgrade');
          confirmBtnID = 'official_version_launched_upgrade';
          handleCancel = () => {
            addNewTrialSurvey(surveyResult, {
              onSuccess: (res) => {
                if (res.code === 20000) {
                  uiState.closeModal();
                }
              },
            });
          };
          handleConfirm = handleUpgrade;
        } else {
          title = t('modal.paymentSurvey.trialEnd.title');
          description = t('modal.paymentSurvey.trialEnd.description');
          cancelBtnText = t('modal.paymentSurvey.trialEnd.skip');
          confirmBtnText = t('modal.paymentSurvey.trialEnd.upgrade');
          handleCancel = () => {
            setSurveyStatus(SurveyStatus.INFO);
          };
          handleConfirm = handleUpgrade;
        }
        break;
      }
      case SurveyStatus.INFO: {
        if (uiState.modalType === ModalTypesEnum.UNSUBSCRIBE_PAYMENT) {
          title = t('modal.paymentSurvey.unsubscribe.title');
          description = t('modal.paymentSurvey.unsubscribe.description');
          cancelBtnText = t('modal.paymentSurvey.unsubscribe.cancel');
          cancelBtnID = 'keep_plan';
          confirmBtnText = t('modal.paymentSurvey.unsubscribe.continue');
          isNavigate = true;
          handleCancel = () => uiState.closeModal();
          handleConfirm = () => setSurveyStatus(SurveyStatus.ONE);
          blocks = [
            {
              title: t('modal.paymentSurvey.unsubscribe.block1.title'),
              info: [
                {
                  title: (
                    <Trans
                      i18nKey="modal.paymentSurvey.unsubscribe.block1.info1"
                      values={{ customers: outcome?.customersUniqCount || 0 }}
                    />
                  ),
                  type: 'success',
                },
                {
                  title: (
                    <Trans
                      i18nKey="modal.paymentSurvey.unsubscribe.block1.info2"
                      values={{ flows: outcome?.flowsCount || 0 }}
                    />
                  ),
                  type: 'success',
                },
                {
                  title: (
                    <Trans
                      i18nKey="modal.paymentSurvey.unsubscribe.block1.info3"
                      values={{ engagement: outcome?.engagementCount || 0 }}
                    />
                  ),
                  type: 'success',
                },
              ],
            },
            {
              title: t('modal.paymentSurvey.unsubscribe.block2.title'),
              info: [
                {
                  title: t('modal.paymentSurvey.unsubscribe.block2.info1'),
                  type: 'error',
                },
                {
                  title: t('modal.paymentSurvey.unsubscribe.block2.info2'),
                  type: 'error',
                },
                {
                  title: t('modal.paymentSurvey.unsubscribe.block2.info3'),
                  type: 'error',
                },
                {
                  title: t('modal.paymentSurvey.unsubscribe.block2.info4'),
                  type: 'error',
                },
              ],
            },
            {
              title: t('modal.paymentSurvey.unsubscribe.block3.title'),
              info: [
                {
                  title: t('modal.paymentSurvey.unsubscribe.block3.info1'),
                  type: 'normal',
                },
                {
                  title: t('modal.paymentSurvey.unsubscribe.block3.info2'),
                  type: 'normal',
                },
              ],
            },
          ];
        } else {
          title = t('modal.paymentSurvey.viewOnlyMode.title');
          description = t('modal.paymentSurvey.viewOnlyMode.description');
          cancelBtnText = t('modal.paymentSurvey.viewOnlyMode.cancelButton');
          confirmBtnText = t('modal.paymentSurvey.viewOnlyMode.confirmButton');
          blocks = [
            {
              title: t('modal.paymentSurvey.viewOnlyMode.block1.title'),
              info: [
                {
                  title: t('modal.paymentSurvey.viewOnlyMode.block1.info1'),
                  type: 'normal',
                },
                {
                  title: t('modal.paymentSurvey.viewOnlyMode.block1.info2'),
                  type: 'normal',
                },
              ],
            },
            {
              title: t('modal.paymentSurvey.viewOnlyMode.block2.title'),
              info: [
                {
                  title: t('modal.paymentSurvey.viewOnlyMode.block2.info1'),
                  type: 'error',
                },
                {
                  title: t('modal.paymentSurvey.viewOnlyMode.block2.info2'),
                  type: 'error',
                },
                {
                  title: t('modal.paymentSurvey.viewOnlyMode.block2.info3'),
                  type: 'error',
                },
                {
                  title: t('modal.paymentSurvey.viewOnlyMode.block2.info4'),
                  type: 'error',
                },
              ],
            },
          ];
          handleCancel = () => setSurveyStatus(SurveyStatus.ONE);
          handleConfirm = handleUpgrade;
        }
        break;
      }
      case SurveyStatus.ONE: {
        if (uiState.modalType === ModalTypesEnum.TRIAL_END) {
          title = t('modal.paymentSurvey.survey1.title');
          confirmBtnText = t('modal.paymentSurvey.survey1.action.next');
          cancelBtnText = t('modal.paymentSurvey.survey1.action.back');
          handleCancel = () => setSurveyStatus(SurveyStatus.INFO);
          handleConfirm = handleSurveyConfirm;
          disabled = shouldConfirmBtnDisabled;
        } else {
          title = t('modal.paymentSurvey.survey1.title');
          confirmBtnText = t('modal.paymentSurvey.survey1.action.continue');
          cancelBtnText = t('modal.paymentSurvey.survey1.action.back');
          handleCancel = () => setSurveyStatus(SurveyStatus.INFO);
          handleConfirm = handleSurveyConfirm;
          disabled = shouldConfirmBtnDisabled;
        }
        break;
      }
      case SurveyStatus.TWO: {
        if (uiState.modalType === ModalTypesEnum.TRIAL_END) {
          title = t('modal.paymentSurvey.survey2.title');
          confirmBtnText = t('modal.paymentSurvey.survey2.action.done');
          cancelBtnText = t('modal.paymentSurvey.survey1.action.back');
          handleCancel = handleSurveyBack;
          handleConfirm = handleSurveyConfirm;
          disabled = shouldConfirmBtnDisabled;
        } else {
          title = t('modal.paymentSurvey.survey2.title');
          confirmBtnText = t('modal.paymentSurvey.survey2.action.continue');
          cancelBtnText = t('modal.paymentSurvey.survey1.action.back');
          handleCancel = handleSurveyBack;
          handleConfirm = handleSurveyConfirm;
          disabled = shouldConfirmBtnDisabled;
        }
        break;
      }
      default:
      case SurveyStatus.DONE: {
        if (uiState.modalType === ModalTypesEnum.UNSUBSCRIBE_PAYMENT) {
          title = t('modal.paymentSurvey.unsubscribe.title');
          confirmBtnText = t('modal.paymentSurvey.surveyDone.unsubscribe.done');
        } else {
          title = t('modal.paymentSurvey.surveyDone.trialEnd.title');
          confirmBtnText = t(
            'modal.paymentSurvey.surveyDone.trialEnd.action.backToPlatform',
          );
        }
        handleConfirm = handleSurveyConfirm;

        break;
      }
    }

    return {
      title,
      description,
      blocks,
      cancelBtnText,
      confirmBtnText,
      isNavigate,
      disabled,
      shouldConfirmBtnDisabled,
      handleCancel,
      handleConfirm,
      cancelBtnID,
      confirmBtnID,
    };
  }, [
    surveyStatus,
    uiState.modalType,
    uiState.closeModal,
    handleUpgrade,
    t,
    shouldConfirmBtnDisabled,
    handleUpgrade,
    handleSurveyConfirm,
    handleSurveyBack,
    outcome,
  ]);

  useEffect(() => {
    switch (uiState.modalType) {
      case ModalTypesEnum.OFFICIAL_VERSION_LAUNCHED: {
        setSurveyResult({ step: 1, reason: [], challenge: [] });
        setSurveyStatus(SurveyStatus.DATA);
        break;
      }
      case ModalTypesEnum.TRIAL_END: {
        setSurveyResult({ step: 2, reason: [], challenge: [] });
        setSurveyStatus(SurveyStatus.DATA);
        break;
      }
      case ModalTypesEnum.UNSUBSCRIBE_PAYMENT: {
        setSurveyStatus(SurveyStatus.INFO);
        setSurveyResult({ step: 3, reason: [], challenge: [] });
        break;
      }
    }
  }, [uiState.modalType]);

  useEffect(() => {
    handleConfirmBtnDisabled();
  }, [handleConfirmBtnDisabled]);

  return (
    <Dialog
      disableEscapeKeyDown
      autoClose={false}
      size={surveyStatus === SurveyStatus.DONE ? 's' : 'md'}
      title={title}
      cancelBtnText={cancelBtnText}
      cancelButtonID={cancelBtnID}
      confirmButtonID={confirmBtnID}
      confirmBtnText={confirmBtnText}
      isNegative={isNavigate}
      closeBtn={false}
      open={true}
      handleClose={handleCancel}
      handleConfirm={handleConfirm}
      disableConfirmButton={disabled}
      // 不知為何無法轉換成 capitalize
      uppercase={false}
    >
      <ContentContainerStyled>
        {surveyStatus === SurveyStatus.DATA && (
          <Fragment>
            <Typography variant="body1" color="grey.800">
              {description}
            </Typography>
            {uiState.modalType === ModalTypesEnum.OFFICIAL_VERSION_LAUNCHED && (
              <Typography variant="body1" color="grey.800">
                <Trans
                  i18nKey={t('modal.officialLaunched.description2')}
                  components={[
                    <strong />,
                    <Link
                      href={PRICING_PAGE}
                      underline="always"
                      target="_blank"
                      sx={{
                        fontWeight: 700,
                        color: 'grey.800',
                      }}
                    />,
                  ]}
                />
              </Typography>
            )}
            <DataContainerStyled>
              <Typography
                variant="body2"
                color="grey.800"
                sx={{ fontWeight: 700 }}
                component="div"
              >
                <Trans
                  i18nKey={t('modal.officialLaunched.congrats', {
                    price: formatNumberWithCommas(totalAmountSaved),
                  })}
                  components={[
                    <Typography
                      variant="h4"
                      color="primary.main"
                      sx={{ display: 'inline', fontWeight: 700 }}
                    />,
                  ]}
                />
              </Typography>
              <DataContentContainerStyled>
                <DataBlock
                  icon={
                    <AccountTreeIcon
                      sx={{
                        width: '16px',
                        height: '16px',
                        color: 'primary.main',
                      }}
                    />
                  }
                  num={outcome ? outcome.flowsCount : 0}
                  title={t('modal.officialLaunched.createdFlows')}
                />
                <DataBlock
                  icon={
                    <PeopleAltIcon
                      sx={{
                        width: '16px',
                        height: '16px',
                        color: 'primary.main',
                      }}
                    />
                  }
                  num={outcome ? outcome.customersUniqCount : 0}
                  title={t('modal.officialLaunched.customers')}
                />
                <DataBlock
                  icon={
                    <SmsIcon
                      sx={{
                        width: '16px',
                        height: '16px',
                        color: 'primary.main',
                      }}
                    />
                  }
                  num={outcome ? outcome.engagementCount : 0}
                  title={t('modal.officialLaunched.engagement')}
                />
              </DataContentContainerStyled>
            </DataContainerStyled>
          </Fragment>
        )}

        {surveyStatus === SurveyStatus.INFO && (
          <Fragment>
            <Box>
              <Typography variant="body1" color="grey.800" mb="20px">
                {uiState.modalType === ModalTypesEnum.TRIAL_END ? (
                  <Trans
                    i18nKey="modal.paymentSurvey.viewOnlyMode.description"
                    components={[
                      <strong />,
                      <Link
                        href={PRICING_PAGE}
                        underline="always"
                        target="_blank"
                        sx={{
                          fontWeight: 700,
                          color: 'grey.800',
                        }}
                      />,
                    ]}
                  />
                ) : (
                  description
                )}
              </Typography>
              <InfoBlockContainerStyled>
                {blocks.map((block: BlockType, index) => (
                  <InfoBlockWrapperStyled key={index}>
                    <Typography variant="subtitle2" color="grey.800" mb="16px">
                      {block.title}
                    </Typography>
                    {block.info.map((info: BlockInfoType, index) => (
                      <Box
                        key={index}
                        display="inline-flex"
                        gap="8px"
                        alignItems={
                          info.type === 'normal' ? 'flex-start' : 'center'
                        }
                        paddingBottom={
                          block.info.length === index + 1 ? '' : '8px'
                        }
                      >
                        {info.type === 'success' && (
                          <DoneIcon fontSize="tiny" color="success" />
                        )}
                        {info.type === 'error' && (
                          <CloseIcon fontSize="tiny" color="error" />
                        )}
                        {info.type === 'normal' && (
                          <Typography variant="body2" color="grey.800">
                            ・
                          </Typography>
                        )}
                        <Typography variant="body2" color="grey.800">
                          {info.title}
                        </Typography>
                      </Box>
                    ))}
                  </InfoBlockWrapperStyled>
                ))}
              </InfoBlockContainerStyled>
            </Box>
          </Fragment>
        )}

        {surveyStatus === SurveyStatus.ONE && (
          <Fragment>
            <Box>
              <Typography variant="body1" color="grey.800">
                {t('modal.paymentSurvey.survey1.content')}
              </Typography>
            </Box>
            <CheckboxesContainerStyled>
              <Checkbox
                id="1"
                variant="body1"
                color="primary"
                fontWeight={400}
                labelColor="grey.800"
                label={t('modal.paymentSurvey.survey1.options.option1')}
                checked={shouldChecked(1)}
                onChange={(e) => handleCheck(e.target.id, e.target.checked)}
              />
              <Checkbox
                id="2"
                variant="body1"
                color="primary"
                fontWeight={400}
                labelColor="grey.800"
                label={t('modal.paymentSurvey.survey1.options.option2')}
                checked={shouldChecked(2)}
                onChange={(e) => handleCheck(e.target.id, e.target.checked)}
              />
              <Checkbox
                id="3"
                variant="body1"
                color="primary"
                fontWeight={400}
                labelColor="grey.800"
                label={t('modal.paymentSurvey.survey1.options.option3')}
                checked={shouldChecked(3)}
                onChange={(e) => handleCheck(e.target.id, e.target.checked)}
              />
              <Checkbox
                id="4"
                variant="body1"
                color="primary"
                fontWeight={400}
                labelColor="grey.800"
                label={t('modal.paymentSurvey.survey1.options.option4')}
                checked={shouldChecked(4)}
                onChange={(e) => handleCheck(e.target.id, e.target.checked)}
              />
              <CheckboxTextareaContainerStyled>
                <Checkbox
                  id="5"
                  variant="body1"
                  color="primary"
                  fontWeight={400}
                  labelColor="grey.800"
                  label={t('modal.paymentSurvey.survey1.options.option5')}
                  checked={shouldChecked(5)}
                  onChange={(e) => handleCheck(e.target.id, e.target.checked)}
                />
                {shouldSurveyOneFeatureTextareaOpen && (
                  <TextareaContainerStyled>
                    <Textarea
                      ref={surveyOneFeatureTextareaRef}
                      placeholder={t(
                        'modal.paymentSurvey.survey1.options.option5Placeholder',
                      )}
                      limit={TEXT_LIMIT}
                      rows={2}
                      value={surveyOneFeatureText}
                      onChange={(e) => {
                        setSurveyOneFeatureText(e.target.value);
                        setSurveyResult({
                          ...surveyResult,
                          reason: surveyResult.reason.map((item) =>
                            item.idx === 5
                              ? { ...item, desc: e.target.value }
                              : item,
                          ),
                        });
                      }}
                    />
                  </TextareaContainerStyled>
                )}
              </CheckboxTextareaContainerStyled>
              <CheckboxTextareaContainerStyled>
                <Checkbox
                  id="6"
                  color="primary"
                  variant="body1"
                  fontWeight={400}
                  labelColor="grey.800"
                  label={t('modal.paymentSurvey.survey1.options.option6')}
                  checked={shouldChecked(6)}
                  onChange={(e) => handleCheck(e.target.id, e.target.checked)}
                />
                {shouldSurveyOneOtherTextareaOpen && (
                  <TextareaContainerStyled>
                    <Textarea
                      ref={surveyOneOtherTextareaRef}
                      placeholder={t(
                        'modal.paymentSurvey.survey1.options.option6Placeholder',
                      )}
                      limit={TEXT_LIMIT}
                      rows={2}
                      value={surveyOneOtherText}
                      onChange={(e) => {
                        setSurveyOneOtherText(e.target.value);
                        setSurveyResult({
                          ...surveyResult,
                          reason: surveyResult.reason.map((item) =>
                            item.idx === 6
                              ? { ...item, desc: e.target.value }
                              : item,
                          ),
                        });
                      }}
                    />
                  </TextareaContainerStyled>
                )}
              </CheckboxTextareaContainerStyled>
            </CheckboxesContainerStyled>
          </Fragment>
        )}
        {surveyStatus === SurveyStatus.TWO && (
          <Fragment>
            <Box>
              <Typography variant="body1" color="grey.800">
                {t('modal.paymentSurvey.survey2.content')}
              </Typography>
            </Box>
            <CheckboxesContainerStyled>
              <Checkbox
                id="1"
                variant="body1"
                color="primary"
                fontWeight={400}
                labelColor="grey.800"
                label={t('modal.paymentSurvey.survey2.options.option1')}
                checked={shouldChecked(1)}
                onChange={(e) => handleCheck(e.target.id, e.target.checked)}
              />
              <Checkbox
                id="2"
                variant="body1"
                color="primary"
                fontWeight={400}
                labelColor="grey.800"
                label={t('modal.paymentSurvey.survey2.options.option2')}
                checked={shouldChecked(2)}
                onChange={(e) => handleCheck(e.target.id, e.target.checked)}
              />
              <Checkbox
                id="3"
                variant="body1"
                color="primary"
                fontWeight={400}
                labelColor="grey.800"
                label={t('modal.paymentSurvey.survey2.options.option3')}
                checked={shouldChecked(3)}
                onChange={(e) => handleCheck(e.target.id, e.target.checked)}
              />
              <Checkbox
                id="4"
                variant="body1"
                color="primary"
                fontWeight={400}
                labelColor="grey.800"
                label={t('modal.paymentSurvey.survey2.options.option4')}
                checked={shouldChecked(4)}
                onChange={(e) => handleCheck(e.target.id, e.target.checked)}
              />
              <CheckboxTextareaContainerStyled>
                <Checkbox
                  id="5"
                  variant="body1"
                  color="primary"
                  fontWeight={400}
                  labelColor="grey.800"
                  label={t('modal.paymentSurvey.survey2.options.option5')}
                  checked={shouldChecked(5)}
                  onChange={(e) => handleCheck(e.target.id, e.target.checked)}
                />
                {shouldSurveyTwoOtherTextareaOpen && (
                  <TextareaContainerStyled>
                    <Textarea
                      ref={surveyTwoOtherTextareaRef}
                      placeholder={t(
                        'modal.paymentSurvey.survey2.options.option5Placeholder',
                      )}
                      limit={TEXT_LIMIT}
                      rows={2}
                      value={surveyTwoOtherText}
                      onChange={(e) => {
                        setSurveyTwoOtherText(e.target.value);
                        setSurveyResult({
                          ...surveyResult,
                          challenge: surveyResult.challenge.map((item) =>
                            item.idx === 5
                              ? { ...item, desc: e.target.value }
                              : item,
                          ),
                        });
                      }}
                    />
                  </TextareaContainerStyled>
                )}
              </CheckboxTextareaContainerStyled>
            </CheckboxesContainerStyled>
          </Fragment>
        )}
        {surveyStatus === SurveyStatus.DONE && (
          <Fragment>
            <Typography variant="body1" color="grey.800">
              {uiState.modalType === ModalTypesEnum.TRIAL_END ? (
                t('modal.paymentSurvey.surveyDone.trialEnd.content.contentTop')
              ) : (
                <Trans
                  i18nKey="modal.paymentSurvey.surveyDone.unsubscribe.content"
                  values={{ trialEndDate: project?.expiryDate }}
                  components={{ strong: <strong /> }}
                />
              )}
            </Typography>
            {uiState.modalType === ModalTypesEnum.TRIAL_END ? (
              <Fragment>
                <Box>
                  <Typography variant="body1" color="grey.800">
                    {t(
                      'modal.paymentSurvey.surveyDone.trialEnd.content.contentMiddle.title',
                    )}
                  </Typography>
                  <ListContainerStyled>
                    <ListItem sx={{ display: 'list-item', p: 0 }}>
                      <Typography variant="body1" color="grey.800">
                        <Trans i18nKey="modal.paymentSurvey.surveyDone.trialEnd.content.contentMiddle.content1">
                          This project will be in
                          <a
                            href="https://industrious-marigold-f2e.notion.site/GoSky-AI-Chatbot-Platform-FAQ-29070a7b43854bc6ad8d9aa2fedce3bd#611f9c1227d84338b81694bfcbcef45a"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#039BE5' }}
                          >
                            {t(
                              'modal.paymentSurvey.surveyDone.trialEnd.content.contentMiddle.content1Link',
                            )}
                          </a>
                        </Trans>
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', p: 0 }}>
                      <Typography variant="body1" color="grey.800">
                        <Trans
                          i18nKey="modal.paymentSurvey.surveyDone.trialEnd.content.contentMiddle.content2"
                          values={{ trialEndDate: project?.expiryDate }}
                          components={{ strong: <strong /> }}
                        />
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', p: 0 }}>
                      <Typography variant="body1" color="grey.800">
                        <Trans
                          i18nKey="modal.paymentSurvey.surveyDone.trialEnd.content.contentMiddle.content3"
                          components={{ strong: <strong /> }}
                        />
                      </Typography>
                    </ListItem>
                  </ListContainerStyled>
                </Box>
                <Typography variant="body1" color="grey.800">
                  {t(
                    'modal.paymentSurvey.surveyDone.trialEnd.content.contentBottom',
                  )}
                </Typography>
              </Fragment>
            ) : (
              <img src={DoneImage} alt="survey-done" />
            )}
          </Fragment>
        )}
      </ContentContainerStyled>
    </Dialog>
  );
}

export default PaymentSurveyModal;
