import { Box, TextField } from '@mui/material';
import { styled } from '@mui/system';
import Button from '../button/button';

export const ChipEditorContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflowX: 'hidden',
}));

export const ChipsContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  width: '100%',
  marginTop: '16px',
}));

export const ChipsStyled = styled(Box)<{ $backgroundColor?: string }>(
  ({ theme, $backgroundColor }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: $backgroundColor ?? '#0000001A',
    width: 'fit-content',
    borderRadius: '100px',
    padding: '4px 12px',
    margin: '0 10px 12px 0',
    fontSize: '14px',
    maxWidth: '100%',
    whiteSpace: 'nowrap',

    '&.with-delete-icon': {
      paddingRight: '8px',
    },

    '.MuiAutocomplete-endAdornment': {
      display: 'none',
    },

    '.MuiAutocomplete-inputRoot': {
      padding: '0 !important',
      fontSize: '14px',
    },

    fieldset: {
      border: 0,
    },
  }),
);

export const ChipContentStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  maxWidth: 'calc(100% - 28px)',
  overflow: 'hidden',
}));

export const TextFieldStyled = styled(TextField)(({ theme }) => ({
  width: '100%',
  minWidth: '55px',
}));

export const AddChipButtonStyled = styled(Button)(({ theme }) => ({
  border: `1px dashed ${theme.palette['grey'][400]}`,
  color: theme.palette['grey'][600],
  borderRadius: '100px',
  width: 'fit-content',
  fontSize: '14px',
}));

export const ChipsContainerWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const InputContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
}));
