import { globalTheme } from '@frontend/components/external-providers';
import {
  ChipEditorChipType,
  ChipEditorSelectOptionType,
} from '@frontend/components/interface';
import { ChipEditor, Typography } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import {
  useGetTagsByFrequency,
  usePatchContactTag,
} from '@frontend/sorghum/data-access';
import {
  PatchContactTagRequestType,
  TagItemType,
} from '@frontend/sorghum/interface';
import {
  PEOPLE_DETAIL_MODAL_TAG_SEE_DETAIL,
  sendGAEvent,
} from '@frontend/sorghum/utils';
import { Box, Link } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface PeopleDetailTagTabProps {
  tagDataList: TagItemType[];
  contactID: string;
  username: string;
}

const TagTabContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '20px 0',
  overflowX: 'hidden',
}));

export function PeopleDetailTagTab({
  tagDataList,
  contactID,
  username,
}: PeopleDetailTagTabProps) {
  const [t] = useTranslation();

  const { data: projectID } = useGetProjectID();
  const { data: tagOptionList } = useGetTagsByFrequency({
    projectID: projectID as string,
  });
  const { mutate: updateTag } = usePatchContactTag();

  const [tagList, setTagList] = useState<ChipEditorChipType[]>([]);

  const onUpdateTag = (
    chipID: string,
    props: ChipEditorSelectOptionType,
    type?: 'add' | 'delete',
  ) => {
    if (type === 'add') {
      const updatedList = tagList.map((item) =>
        item.id === chipID
          ? { ...item, label: props.label, value: props.value }
          : item,
      );
      setTagList(updatedList);

      const formattedProps: PatchContactTagRequestType = {
        contactsId: contactID,
        create: [
          {
            name: props.label ?? '',
            id: props.value ?? '',
            ...(props.newTag && {
              newTag: true,
            }),
          },
        ],
        delete: [],
      };
      updateTag(formattedProps);
    } else if (type === 'delete') {
      const formattedProps: PatchContactTagRequestType = {
        contactsId: contactID,
        create: [],
        delete: [chipID],
      };
      updateTag(formattedProps);
    }
  };

  const formattedTagList = tagOptionList
    ? tagOptionList.map((item) => ({
        label: item.name,
        value: item.id,
      }))
    : [];

  useEffect(() => {
    if (tagDataList) {
      const formattedList: ChipEditorChipType[] = tagDataList.map((item) => ({
        id: item.id,
        label: item.name,
        lastUpdated: item.lastUpdated,
        value: '',
      }));

      setTagList(formattedList);
    }
  }, [tagDataList]);

  return (
    <TagTabContainerStyled>
      <Typography variant="body3">
        {t('people.detailModal.tagTab.description')}
        <Link href={PEOPLE_DETAIL_MODAL_TAG_SEE_DETAIL}>
          {t('people.detailModal.tagTab.seeDetails')}
        </Link>
      </Typography>
      <Box sx={{ marginTop: '16px' }}>
        <ChipEditor
          tagList={tagList}
          setTagList={setTagList}
          addButtonText={t('people.detailModal.tagTab.buttonText')}
          firstOptionList={formattedTagList}
          isAllowFirstSelectAddOption
          isNeedConfirmDelete
          handleAddClick={() => {
            sendGAEvent(
              'Customers',
              '＋add tag_detail',
              'Customers - User- Detail - ＋add tag - click',
              '',
            );
          }}
          handleDeleteClick={() =>
            sendGAEvent(
              'Customers',
              'tag_X_detail',
              'Customers - User- Detail - tag_X - click',
              '',
            )
          }
          onUpdateFirstChipValue={onUpdateTag}
          chipBackgroundColor={`${get(
            globalTheme,
            'palette.primary.main',
            '',
          )}0D`}
          chipFirstTextColor={get(globalTheme, 'palette.primary.main', '')}
          cancelButtonColor="primary.main"
          username={username}
          disableOptionTooltip={t(
            'people.detailModal.tagTab.disableOptionText',
          )}
          chipTooltipLabelName={t('people.detailModal.tagTab.tooltipLabel')}
        />
      </Box>
    </TagTabContainerStyled>
  );
}

export default PeopleDetailTagTab;
