import { Dialog, NoticeBox, Typography } from '@frontend/components/ui';
import {
  PublishStatus,
  UsePagePersistentMenuReturn,
} from '@frontend/sorghum/interface';
import {
  BasicSetting,
  PageContainer,
  PersistentMenuFacebook,
  PersistentMenuPreview,
} from '@frontend/sorghum/ui';
import { Box, Button, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

export interface SorghumFeaturesPersistentMenuProps {
  usePage: () => UsePagePersistentMenuReturn;
}

const PersistentMenuContainerStyled = styled(Box)(({ theme }) => ({
  height: '100%',
  minWidth: '100%',
  width: 'fit-content',
}));

const InstagramContent = () => {
  return <Box></Box>;
};

export function SorghumFeaturesPersistentMenu({
  usePage,
}: SorghumFeaturesPersistentMenuProps) {
  const {
    tabValue,
    setTabValue,
    //***** 以下為新 */
    formData,
    publishStatus,
    breadcrumbItems,
    breadcrumbNow,
    isSaving,
    onSave,
    onPublish,
    menuStatus,
    setMenuStatus,
    changeSort,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    deleteMenuItem,
    openDeleteModal,
    setIsOpenUnpublishedFlowModal,
    isOpenUnpublishedFlowModal,
    unpublishedFlows,
    addNewMenuItem,
    publishErrors,
    setFieldValue,
    setItemData,
    onUpdateFlowID,
    getPublishStatus,
  } = usePage();

  const [t] = useTranslation();

  return (
    <>
      <PageContainer
        history={breadcrumbItems}
        now={breadcrumbNow}
        customButton={
          <Tooltip title={t('common.topBar.realTimeInfo')} placement="top">
            <Button
              variant="contained"
              size="small"
              color={
                publishStatus === PublishStatus.UNPUBLISH
                  ? 'primary'
                  : 'secondary'
              }
              disabled={publishStatus === PublishStatus.PUBLISHED && !isSaving}
              onClick={onPublish}
            >
              {t(
                `common.topBar.${
                  publishStatus === PublishStatus.UNPUBLISH
                    ? 'publish'
                    : 'update'
                }`,
              )}
            </Button>
          </Tooltip>
        }
        isShowNoticeBox={publishStatus === PublishStatus.UNPUBLISHED_CHANGES}
        noticeBoxChildren={
          <NoticeBox
            message={t('basicSetting.alert.unpublishChange')}
            linkText={t('common.update')}
            onLinkClick={onPublish}
          />
        }
      >
        <PersistentMenuContainerStyled>
          <BasicSetting
            title={t('basicSetting.persistentMenu.title')}
            tabValue={tabValue}
            menuStatus={menuStatus}
            setTabValue={setTabValue}
            setMenuStatus={setMenuStatus}
            facebookContent={
              <PersistentMenuFacebook
                formData={formData}
                changeSort={changeSort}
                onSave={onSave}
                isEditable={menuStatus}
                openDeleteModal={openDeleteModal}
                setIsOpenUnpublishedFlowModal={setIsOpenUnpublishedFlowModal}
                isOpenUnpublishedFlowModal={isOpenUnpublishedFlowModal}
                unpublishedFlows={unpublishedFlows}
                addNewMenuItem={addNewMenuItem}
                publishErrors={publishErrors}
                setFieldValue={setFieldValue}
                setItemData={setItemData}
                onUpdateFlowID={onUpdateFlowID}
                getPublishStatus={getPublishStatus}
                publishStatus={publishStatus}
              />
            }
            previewPosition="bottom"
            instagramContent={<InstagramContent />}
            mobilePreviewContent={<PersistentMenuPreview menu={formData} />}
          />
        </PersistentMenuContainerStyled>
      </PageContainer>
      <Dialog
        size="xs"
        color="error"
        title={t('basicSetting.persistentMenu.modal.title')}
        open={isOpenDeleteModal}
        handleClose={() => setIsOpenDeleteModal(false)}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.delete')}
        handleConfirm={deleteMenuItem}
      >
        <Box>
          <Typography color="grey.800" variant="body1">
            {t('basicSetting.persistentMenu.modal.info')}
          </Typography>
        </Box>
      </Dialog>
    </>
  );
}

export default SorghumFeaturesPersistentMenu;
